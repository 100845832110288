document.addEventListener("turbolinks:load", () => {
    if($('#map').length){
        toggleMapFilters();
        toggleMapLegend();
        toggleMapInfo();
        toggleMapExport();
        toggleMapZones();
    }
});

const toggleMapFilters = () => {
    // Opening map filters
    document.getElementById('map-show-filters-button').onclick = function () {
        openMenu('filters-popout-menu');
    }

    // Closing map filters
    document.getElementById('close-filters-popout').onclick = function () {
        closePopoutMenus();
    }
}

const toggleMapLegend = () => {
    document.getElementById('map-show-legend-button').onclick = function () {
        // if(document.getElementById('timeline')){
        //     document.getElementById('timeline').setAttribute('style', 'display:none !important');
        // }
        openMenu('legend-popout-menu');
    }
    document.getElementById('close-legend-popout').onclick = function () {
        // if(document.getElementById('timeline')){
        //     document.getElementById('timeline').setAttribute('style', 'display:flex !important');
        // }
        closePopoutMenus();
    }
}

const toggleMapInfo = () => {
    const $mapInfoToggleButton = $(".map-info-toggle");
    $mapInfoToggleButton.click((e) => {
        let map_info_container = document.querySelector('.map-info-container');
        map_info_container.classList.remove('animate__slideInRight');
        map_info_container.classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
        map_info_container.setAttribute('style', 'visibility:hidden');
    });
}

const toggleMapZones = () => {
    const $mapZoneToggleButton = $("#map-show-zones-button");
    const $mapStatsContainer = $(".stats-menu")
    $mapZoneToggleButton.click((e) => {
        if($mapStatsContainer.is(':visible')){
            $mapStatsContainer.hide();
        }else{
            $mapStatsContainer.show();
        }
    });
}

const toggleMapExport = () => {
    document.getElementById('map-show-export-button').onclick = function () {
        openMenu('export-popout-menu');
    };
    document.getElementById('close-export-popout').onclick = function () {
        closePopoutMenus();
    }
}

function openMenu(menu_name){
    document.getElementById(menu_name).classList.remove('animate__slideOutRight');
    document.getElementById(menu_name).classList.add('animate__animated', 'animate__slideInRight',  'animate__faster');
    document.getElementById(menu_name).setAttribute('style', 'visibility:visible');
}

function closePopoutMenus(){
    let popout_menus = document.querySelectorAll('.popout-menu');
    for (var i = 0, len = popout_menus.length; i < len; i++) {
        popout_menus[i].classList.remove('animate__slideInRight');
        popout_menus[i].classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
        popout_menus[i].setAttribute('style', 'visibility:hidden');
    }
}