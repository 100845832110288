import Config from "../custom/config/constants";
import 'animate.css'

$(document).on('turbolinks:load', () => {
  if ($('#event_select').length > 0){
    $('#event_select').selectize({
      plugins: ['remove_button']
    });
  }

  let filtersButton =  document.getElementById('event-log-filters-toggle');
  if(filtersButton){
  let filters = document.getElementById('event-log-filters');
   filtersButton .onclick = function () {
     filters.setAttribute('style', 'visibility:visible !important');
     filters.classList.remove('animate__slideOutRight');
     filters.classList.add('animate__animated', 'animate__slideInRight', 'animate__faster');
   }

   document.getElementById('close-event-filters').onclick = function () {
     filters.classList.remove('animate__slideInRight');
     filters.classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
     filters.setAttribute('style', 'visibility:hidden !important');
   }
  }

  const $siteDropdown = $(".event-log-site-dropdown");
  const $userDropdown = $(".event-log-user-dropdown");
  const $deviceDropdown = $(".event-log-device-dropdown");
  if ($siteDropdown.length && $userDropdown.length && $deviceDropdown.length) {
    listenForSiteChange($siteDropdown, $userDropdown, $deviceDropdown);
  }
});

const listenForSiteChange = ($siteDropdown, $userDropdown, $deviceDropdown) => {
  $siteDropdown.change((e) => {
    let siteId = $(e.target).val();

    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}users?apikey=${Config.apiKey}&site_id=${siteId}&event_log=true`,
      dataType: "json",
      success: data => {
        let userDropdownHtml = "";
        userDropdownHtml +=  `<option value="all">All Users</option>`
        $.each(data.allocation_data, (index, user_id) => {
          userDropdownHtml +=  `<option value="${user_id}">${user_id}</option>`
        });
        $userDropdown.empty().append(userDropdownHtml);
      },
      error: data => {
        alert("Error trying to retrieve users data. Please refresh the page and try again.");
      }
    });
    $.ajax({
      type: 'GET',
      url: `${Config.apiUrl}devices?apikey=${Config.apiKey}&site_id=${siteId}&event_log=true`,
      dataType: "json",
      success: data => {
        let deviceDropdownHtml = "";
        deviceDropdownHtml +=  `<option value="all">All Devices</option>`
        $.each(data.device_data, (index, device_id) => {
          deviceDropdownHtml +=  `<option value="${device_id}">${device_id}</option>`
        });
        $deviceDropdown.empty().append(deviceDropdownHtml);
      },
      error: data => {
        alert("Error trying to retrieve users data. Please refresh the page and try again.");
      }
    });
  });
}