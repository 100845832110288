import tippy from "tippy.js";

document.addEventListener("turbolinks:load", function() {
  syncButton();
  siteDashEventStatus();
  siteDashTeamTags();
  siteDashMachineTags();
  siteDashHazardTags();
  siteDashTunnelTags();
  siteDashNetworkStatus();
  siteDashTotalUsers();
  siteDashTotalBumps();
  siteDashTotalBumpTime();
  siteDashTotalUnauthorisedZoneAccesses();
  siteDashTotalGeofenceInteractions();
  userDashName();
  userDashCheckedOutDevice();
  userDashGroup();
  configSelect();
  statsMenuItem();
  geofenceStartDate();
  geofenceEndDate();
  greenGroupSelect();
  amberGroupSelect();
  redGroupSelect();
  greenGeofenceSelect();
  amberGeofenceSelect();
  redGeofenceSelect();
  siteDashZoneStatus();
  hazardZoneType();
  hazardZoneAccessLevel();
  companyDashTotalBumps();
  companyDashTotalDevicesLost();
  companyDashTotalUnauthorisedAccesses();
  let createZoneButton = $("#create-geofence-button");
  if (createZoneButton.hasClass('button is-primary is-disabled')){
    disabledCreateZoneButton();
  }
  totalZonesStat();
  vehicleDistanceFromZones();
  vehicleWarningDistanceFromZones();
  vehicleDistanceFromPlant();
  vehicleWarningDistanceFromPlant();
  planningDrawPolygon();
  planningDrawLine();
  planningTrash();
  ganttHover();
  mapFilters();
  mapKey();
  mapLive();
  mapReset();
  mapExport();
  mapUpload();
  siteDashZoneInts();
  siteDashExtBreaches();
  siteDashRepBreaches();
  nonLatLong();
  coordinatePreference();
  twoFactorAuthentication();
  mapTunnel();
  addToZonesButton();
  upaIdInfo();
  upaFleetNumberInfo();
  upaDescriptionInfo();
  upaMachineTypeInfo();
  machineSenseIdInfo();
  machineSenseFleetNumberInfo();
  machineSenseDescriptionInfo();
  machineSenseMachineTypeInfo();
})

const upaIdInfo = () => {
  tippy('#upa_id_info', {
    content:
        `<p>
            Record the UPA ID number here.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const upaFleetNumberInfo = () => {
  tippy('#upa_fleet_number_info', {
    content:
        `<p>
            Record the fleet number or Machine ID number of the vehicle here.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const upaDescriptionInfo = () => {
  tippy('#upa_description_info', {
    content:
        `<p>
            Record machine details such as weight, vehicle type ie EX15TABC.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const upaMachineTypeInfo = () => {
  tippy('#upa_machine_type_info', {
    content:
        `<p>
            Select from the drop-down menu.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const machineSenseIdInfo = () => {
  tippy('#machine_sense_id_info', {
    content:
        `<p>
            Record the MachineSense ID number here.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const machineSenseFleetNumberInfo = () => {
  tippy('#machine_sense_fleet_number_info', {
    content:
        `<p>
            Record the fleet number or Machine ID number of the vehicle here.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const machineSenseDescriptionInfo = () => {
  tippy('#machine_sense_description_info', {
    content:
        `<p>
            Record machine details such as weight, vehicle type ie EX15TABC.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const machineSenseMachineTypeInfo = () => {
  tippy('#machine_sense_machine_type_info', {
    content:
        `<p>
            Select from the drop-down menu.
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const addToZonesButton = () => {
  tippy('#add-to-zones-button-div', {
    content:
        `<p>
            Select one or more workers below to configure their zone access.
      </p>`
    ,
    allowHTML: true,
    placement: 'top',
    trigger: 'manual'
  })
}

const ganttHover = () => {
  tippy('#gantt', {
    content:
        `<p>
            Double click a bar to see more details
      </p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const planningTrash = () => {
  tippy('#trash-button', {
    content:
        `<p>
            Delete the zone you have drawn
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const planningDrawLine = () => {
  tippy('#line-button', {
    content:
        `<p>
            Draw a new access way
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const planningDrawPolygon = () => {
  tippy('#polygon-button', {
    content:
        `<p>
            Draw a new zone
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const nonLatLong = () => {
  let text = {
    content:
        `<p class="help sync-help-text">
        Since your company uses a different coordinate reference system
        to the one PLINX uses (WGS84), values entered here will be
        converted into WGS84 for our systems. When you view location data,
        it will be converted back into the coordinate reference system that 
        your company uses. An error of roughly 1cm can occur due to these conversions.
      </p>`
    ,
    allowHTML: true,
    placement: 'left'
  }
  tippy('#easting-box', text);
  tippy('#northing-box', text);
}

const syncButton = () => {
  tippy('#sync-device-button', {
    content:
      `<p class="help sync-help-text">
        This button kicks off a background process which syncs devices and sites. 
        It should be run when <b>new</b> devices are set up on a site for the first time
        or existing devices are <b>relocated</b> to a new site. It gets the new or relocated devices and attempts
        to associate them with a site using the <i>network_id</i>. This Process will also be run nightly as a
        fallback option to make sure everything is up to date.
      </p>`
    ,
    allowHTML: true,
    placement: 'left'
  });
}

const siteDashEventStatus = () => {
  tippy('#site-event-status-box', {
    content:
        `<p>
            Latest events on site, within the last 10 minutes.
            <p><i>Red</i> - A major event occurred, such as SOS or unauthorized zone breach.</p>
            <p><i>Amber</i> - An event that may need further investigation occurred such as a timeout or user being at risk.</p>
            <p><i>Green</i> - No serious events currently on site.</p>
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashNetworkStatus = () => {
  tippy('#site-network-status-box', {
    content:
        `<p>
            Current network status of a site.
            <p><i>Red</i> - The network is down or has a fault.</p>
            <p><i>Amber</i> - The network is under high load or may be experiencing some issues.</p>
            <p><i>Green</i> - The network is running fine.</p>
            <p><i>Grey</i> - The site does not have a connect</p>
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashTotalUnauthorisedZoneAccesses = () => {
  tippy('#site-dash-total-unauthorised-zone-accesses', {
    content:
    `<p>
        The number of unauthorised zone accesses that have occurred at this site in the specified time period.
    </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashTotalGeofenceInteractions = () => {
  tippy('#site-dash-total-total-geofence-interactions', {
    content:
    `<p>
        The number of geofence interactions at this site in the specified time period
    </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashTeamTags = () => {
  tippy('#site-active-users-box', {
    content:
        `<p>
            The number of people who currently have a TeamTag checked out on site.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashMachineTags = () => {
  tippy('#site-active-machines-box', {
    content:
        `<p>
            The number of MachineTags currently on site.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashHazardTags = () => {
  tippy('#site-active-hazard-tags-box', {
    content:
        `<p>
            The number of HazardTags currently on site.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashTunnelTags = () => {
  tippy('#site-active-tunnel-tags-box', {
    content:
        `<p>
            The number of TunnelTags currently on site.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashTotalUsers = () => {
  tippy('#site-dash-total-users-box', {
    content:
        `<p>
            The number of users on site who checked out a device in the specified time period.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const companyDashTotalBumps = () => {
  tippy('#company-dash-total-bumps-box', {
    content:
    `<p>
       The total number of bumps that have occurred on all sites in the given time period.
    </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const companyDashTotalDevicesLost = () => {
  tippy('#company-dash-total-devices-lost', {
    content:
    `<p>
        The total number of devices lost on all sites in the given time period.
     </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const companyDashTotalUnauthorisedAccesses = () => {
  tippy('#company-dash-total-unauthorised-accesses', {
    content:
    `<p>
        The total number of unauthorised zone accesses that have occurred on all sites in the given time period.
    </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashTotalBumps = () => {
  tippy('#site-dash-total-bumps-box', {
    content:
        `<p>
            The total number of bumps that occurred in the given time period. Click to see the breakdown of events.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const siteDashTotalBumpTime = () => {
  tippy('#site-dash-total-bump-time-box', {
    content:
        `<p>
            The total amount of time all users spent in close proximity to another user for the given time period.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const userDashName = () => {
  tippy('#user-dash-name-box', {
    content:
        `<p>
            The name of the user, only available if sensitive data is currently turned on.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const userDashCheckedOutDevice = () => {
  tippy('#user-dash-device-box', {
    content:
        `<p>
            The current device that has been checked out by the user, if there is one.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const userDashGroup = () => {
  tippy('#user-dash-group-box', {
    content:
        `<p>
            The group assigned to the user from the Group Configuration tab.
      </p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  });
}

const configSelect = () => {
  tippy('#config-select-div', {
    content:
        `<li>Bump Distance: Distance at which a bump is detected, in meters.</li>
          <li>SOS Active: Whether the user can trigger an SOS Alert.</li>
          <li>Bump Alerts: Whether the user should receive bump alerts on the device.</li>
          <li>Timeout End Alerts: Whether the user should receive an alert when their timeout ends.</li>
          <li>At Risk Alerts: Whether the user should receive at risk alerts. At risk is when a users device is not connected properly.</li>
          <li>Low Battery Alerts: Whether the user should receive an alert when the battery on their TeamTag is running low.</li>
          <li>Hazard Zone Alerts: Whether the user should receive alerts when entering hazard zones.</li>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const statsMenuItem = () => {
  tippy('#stats-menu', {
    content:
        `<p>Click a hazard zone name for interaction stats.</p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const hazardZoneType = () => {
  tippy('#hazard-zone-type', {
    content:
        `<p>Hazard Zones are areas that can be designated with an access level, such as allowed, warning and not allowed, PLINX users will be alerted accordingly when entering these zones.<br/><br/>
 Access Ways provide routes that users can use for access and are made up of lines with a given offset in meters.<br/><br/>
 Perimeters outline the boundary of the site.</p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const geofenceStartDate = () => {
  tippy('#zone_start_date', {
    content:
        `<p>This is the date interactions with the zone will begin being processed. If this is in the past, it will retrospectively process interactions with the zone when you draw the area, however this may take some time if the start date is a long time ago.</p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const geofenceEndDate = () => {
  tippy('#zone_end_date', {
    content:
        `<p>This is the date the zone will no longer be recognised by the TeamTag. Once the date has passed, the zone will deactivate.</p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const hazardZoneAccessLevel = () => {
  tippy('#hazard-zone-access-level', {
    content:
        `<p><span class="is-white is-bold">Allowed</span>- Appears <span class="is-green">Green</span> on the map.<br/><span class="is-white is-bold">Zone Definition</span>: Potential low risk of hazards, foot traffic can move freely but are monitored.<br/><span class="is-white is-bold">Example</span>: Storage Areas / Access Ways / Briefing Areas.</p><br/>
          <p><span class="is-white is-bold">Not Allowed</span>- Appears <span class="is-red">Red</span> on the map.<br/><span class="is-white is-bold">Zone Definition</span>: High risk hazards present, foot traffic cannot enter this zone (unless exceptions are specified).<br/><span class="is-white is-bold">Example</span>: Earthworks Areas / Plant In Use / Non Pedestrian Zone.</p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const greenGroupSelect = () => {
  tippy('#green_group_select', {
    content:
        `<p>Users selected here are allowed to enter the zone without warning.</p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const amberGroupSelect = () => {
  tippy('#amber_group_select', {
    content:
        `<p>Users selected here are allowed to enter the zone but will get a warning on their TeamTag.</p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const redGroupSelect = () => {
  tippy('#red_group_select', {
    content:
        `<p>Users selected here are not allowed to enter the zone and will receive an alert on their TeamTag that they cannot disable.</p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const greenGeofenceSelect = () => {
  tippy('#worker_group_green_geofence', {
    content:
        `<p>Select the hazard zone that this group of users can enter.</p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const amberGeofenceSelect = () => {
  tippy('#worker_group_amber_geofence', {
    content:
        `<p>Select the hazard zone that this group of users can enter using a timeout, but will get a device warning for.</p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const redGeofenceSelect = () => {
  tippy('#worker_group_red_geofence', {
    content:
        `<p>Select the hazard zone that this group of users cannot enter.</p>`
    ,
    allowHTML: true,
    placement: 'top'
  });
}

const siteDashZoneStatus = () => {
  tippy('#site-dash-zone-status-table', {
    content:
        `<p>Click a zone for a breakdown of interactions within the selected time period.</p>`
    ,
    allowHTML: true,
    placement: 'left',
    trigger: 'manual',
    showOnCreate: true,
    hideOnClick: true
  })
}

const totalZonesStat = () => {
  tippy('#total_zones_stat', {
    content:
        `<p>This is only visible to super admins.</p>`
    ,
    allowHTML: true,
    placement: 'bottom'
  })
}

const disabledCreateZoneButton = () => {
    tippy('#create-geofence-button', {
      content:
          `<p>The maximum active hazard zones for site and sector has been reached.</p>`
      ,
      allowHTML: true,
      placement: 'bottom'
    })
}

const vehicleDistanceFromZones = () => {
  tippy('#vehicle-distance-from-zone', {
    content:
        `<p>The distance the vehicle should keep from hazard zones (in meters).</p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const vehicleWarningDistanceFromZones = () => {
  tippy('#vehicle-warning-distance-from-zone', {
    content:
        `<p>The distance from zones that the vehicle will begin receiving warning alerts (in meters).</p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const vehicleDistanceFromPlant = () => {
  tippy('#vehicle-distance-from-plant', {
    content:
        `<p>The distance from plant that the vehicle will begin a breach (in meters).</p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const vehicleWarningDistanceFromPlant = () => {
  tippy('#vehicle-warning-distance-from-plant', {
    content:
        `<p>The distance from plant that the vehicle will begin receiving warning alerts (in meters).</p>`
    ,
    allowHTML: true,
    placement: 'right'
  })
}

const mapUpload = () => {
  tippy('#upload-button', {
    content:
        `<p>
            Upload imagery to the map (geotiff, geojson, kml)
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const mapExport = () => {
  tippy('#map-show-export-button', {
    content:
        `<p>
            Export data from the map
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const mapReset = () => {
  tippy('.map-reset-filters', {
    content:
        `<p>
            Reset the map
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const mapLive = () => {
  tippy('.map-live-data', {
    content:
        `<p>
            View live data for the site
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const mapKey = () => {
  tippy('#map-show-legend-button', {
    content:
        `<p>
            Map key
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}

const mapFilters = () => {
  tippy('#map-show-filters-button', {
    content:
        `<p>
            Filter data on the map
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });

  tippy('#map-filter', {
    content:
        `<p>
            Filtering high precision data, or a large time period may take some time to load.
      </p>`
    ,
    allowHTML: true,
    placement: 'left'
  })
}

const siteDashZoneInts = () => {
  tippy('#site-dash-zone-int-help', {
    content:
        `<p>
            Number of times PLINX users have interacted with the zone
      </p>`
    ,
    allowHTML: true,
    placement: 'top'
  })
}

const siteDashExtBreaches = () => {
  tippy('#site-dash-ext-breaches-help', {
    content:
        `<p>
            Number of times PLINX users have entered the zone and stayed in the zone for an extended period of time
      </p>`
    ,
    allowHTML: true,
    placement: 'top'
  })
}

const siteDashRepBreaches = () => {
  tippy('#site-dash-rep-breaches-help', {
    content:
        `<p>
            Number of times PLINX users have repeatedly interacted with the zone
      </p>`
    ,
    allowHTML: true,
    placement: 'top'
  })
}

const coordinatePreference = () => {
  tippy('#coordinate-preference-select', {
    content:
        `<p>
            Display coordinates in your company's preferred coordinate reference system. 
      </p>`
    ,
    allowHTML: true,
    placement: 'top'
  })
}

const twoFactorAuthentication = () => {
  tippy('#two-factor-authentication-select', {
    content:
        `<p>
            Turn two factor authentication on/off to fit your company's IT policy.
      </p>`
    ,
    allowHTML: true,
    placement: 'top'
  })
}

const mapTunnel = () => {
  tippy('#map-show-tunnel-button', {
    content:
        `<p>
            Show tunnel data
      </p>`
    ,
    allowHTML: true,
    placement: 'right'
  });
}
