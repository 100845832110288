$(document).on('turbolinks:load', function(){
  // this method will be a generic method for handling any modal on any page
  // it will however not be able to handle multiple modals on one page
  // all you need to do is: add the ID `modal-activator` to the element you want to activate the modal when clicked
  handleModal();
});
function handleModal() {
  var modal = $(".modal");
  $("#modal-activator").click(function (e) {
    //activate modal
    modal.addClass("is-active");
  });
  $(".modal-background, #modal-close").click(function (e) {
    //close modal
    modal.removeClass("is-active");
  });

  var webhook_modal = $("#webhook-modal");
  $("#webhook-modal-activator").click(function (e) {
    //activate modal
    webhook_modal.addClass("is-active");
  });
  $(".webhook-modal-background, #webhook-modal-close").click(function (e) {
    //close modal
    webhook_modal.removeClass("is-active");
  });
}
