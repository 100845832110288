import flatpickr from "flatpickr";

$(document).on('turbolinks:load', function(){
  // for whatever reason, sometimes flatpickr was taking longer to load.
  // so this has been refactored to check that its not null before trying to use it.
  var date_time_picker = document.getElementsByClassName("date-time-picker");
  var date_from_picker = document.getElementsByClassName("date-from-picker");
  var date_to_picker = document.getElementsByClassName("date-to-picker");
  var date_picker_no_time = document.getElementsByClassName('date-picker-no-time');
  var map_date_from_picker = document.getElementsByClassName('map-date-from-picker');
  var map_date_to_picker = document.getElementsByClassName('map-date-to-picker');
  if (date_picker_no_time != null && flatpickr != null) {
    date_picker_no_time.flatpickr({
      dateFormat: "F, d Y H:i",
      defaultHour: 0
    });
  };
  if (date_time_picker != null && flatpickr != null) {
    date_time_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 0
    });
  };
  if (date_from_picker != null && flatpickr != null) {
    date_from_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 0
    });
  };
  if (date_to_picker != null && flatpickr != null) {
    date_to_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 23,
      defaultMinute: 59
    });
  };
  if (map_date_from_picker != null && flatpickr != null) {
    map_date_from_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 0,
      position: 'left'
    });
  };
  if (map_date_to_picker != null && flatpickr != null) {
    map_date_to_picker.flatpickr({
      enableTime: true,
      time_24hr: true,
      dateFormat: "F, d Y H:i",
      defaultHour: 23,
      defaultMinute: 59,
      position: 'left'
    });
  };
  let planning_date_from = document.getElementById('popout-date-from');
  let planning_date_to = document.getElementById('popout-date-to');
  if (planning_date_from != null && flatpickr != null) {
    planning_date_from.flatpickr({
      enableTime: false,
      dateFormat: 'Y-m-d'
    });
    planning_date_to.flatpickr({
      enableTime: false,
      dateFormat: 'Y-m-d'
    })
  }
});
