import {getInitialSite, siteId} from "./map/site_management";
import mapboxgl from "mapbox-gl";
import Config from "./config/constants";
import MapConfig from "./config/map_config";
import {createExceptionRow, fitMapToBounds, geofenceAjaxCall, imageDataAjaxCall, machineZoneAjaxCall, tunnelAjaxCall} from "./map/map_ajax";
import {addPolygonsMouseOver} from "./map/map";
import Swal from 'sweetalert2';

$(document).on('turbolinks:load', () => {
    let machines_switch = $("#machines_switch");
    if(machines_switch.length) {
        listenForSwitch(machines_switch);
        listenForExpand();
        listenForCheckbox();
        listenForMapClick();
        listenForEditClick();
    }

    if ($('#assign_machines_map').length) {
        $.when(getInitialSite()).done(() => {
            initiateAssignMachinesMap();
        })
    }
});

function initiateAssignMachinesMap() {
    const siteId = document.getElementById("site_id").value;
    const siteCoordinates = document.getElementById("site_coordinates").value;
    const geofenceIds = document.getElementById("geofence_ids").value;
    mapboxgl.accessToken = Config.mapboxAccessToken;
    const map = new mapboxgl.Map({
        container: 'assign_machines_map',
        style: MapConfig.regularStyle,
        center: siteCoordinates.split(" "),
        zoom: MapConfig.startingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });
    fitMapToBounds(map, siteId, siteCoordinates);

    map.on('load', () => {
        listenForAddCurrentMachines();
        listenForDoneAssigning();
        listenForMachinesList();
        let is_type_search = $("#is_type_search").val();
        let type = $("#type").val();
        let machine_ids = $("#machine_ids").val().split(",");

        imageDataAjaxCall(map, null, null, null, false, true, true, false, null, siteId, null, false, false, true).then(
            response => machineZoneAjaxCall(
                map,
                geofenceIds,
                true,
                false,
                false,
                is_type_search,
                type,
                machine_ids
            )
        )
        addPolygonsMouseOver(map, 'machine_zones');
    });
}

function listenForAddCurrentMachines() {
    $("#machines-popout-add-machines").on('click', function() {
        Swal.fire({
            title: 'Add machines to zone',
            text: "Are you sure you want to add the selected machines to this zone?",
            icon: 'info',
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                let is_type_search = $("#is_type_search").val();
                let type = $("#type").val();
                let machine_ids = $("#machine_ids").val().split(",");
                let geofence_id = $("#machines-popout-add-machines").data('geofence_id');
                let geofence_name = $("#popout-zone-name").val();
                let exceptionsContainer = document.getElementById('exceptions-container');
                let exceptionsList = document.getElementById('exceptions-list-body');
                $.ajax({
                    type: 'POST',
                    url: `${Config.apiUrl}internal_machines/update_machines_zone_access?apikey=${Config.apiKey}&is_type_search=${is_type_search}&type=${type}&machine_ids=${machine_ids}&machine_zone_id=${geofence_id}`,
                    dataType: "json",
                    success: function() {
                        Swal.fire("Done!", "Machines Added!", "success").then((done) => {
                            exceptionsContainer.style.display = "block";
                            if (is_type_search && is_type_search == "true"){
                                createExceptionRow(type, geofence_id, geofence_name, false, exceptionsList);
                            }
                            for (let i = 0; i < machine_ids.length; i++) {
                                if (machine_ids[i] == '') {
                                    continue;
                                }
                                createExceptionRow(machine_ids[i], geofence_id, geofence_name, false, exceptionsList);
                            }
                        })
                    },
                    error: function() {
                        Swal.fire('There was a problem adding the selected machines to the zone.')
                    }
                });
            } else {
                Swal.fire('Machines not added.')
            }
        })
    });
}

function listenForMachinesList() {
    $("#expand-machines-list").on("click", function() {
        let machines_list = $(".machines-list");
        if(machines_list.is(":visible")) {
            machines_list.hide();
        }else{
            machines_list.show();
        }
    })
}

function listenForCheckbox() {
    let machine_select = $(".machines-hidden-select");
    let machines_with_type = $("#machines_with_type").val();
    let is_type_search = $("#is_type_search");
    let add_to_zones_button = $("#add-machines-to-zones-button");

    $(".select-all-machines-checkbox").on("click", function() {
        if($(this).is(':checked')) {
            $('.machines-checkbox').trigger('click')
        }else {
            $('.machines-checkbox').trigger('click')
        }
    })

    $(".machines-checkbox").on("click", function() {
        let select_string = ".machines-hidden-select option[value=" + $.escapeSelector($(this).attr('checked_value')) + "]"
        if($(this).is(':checked')) {
            $(select_string).prop('selected', true);
        }else{
            $(select_string).prop("selected", false);
        }

        if(machine_select.val().length) {
            add_to_zones_button.prop('disabled', false);
            add_to_zones_button.removeClass('is-disabled');
        }else{
            add_to_zones_button.prop('disabled', true);
            add_to_zones_button.addClass('is-disabled');
        }
    })
}

function listenForDoneAssigning() {
    $("#assign-machines-done").on("click", function() {
        Swal.fire({
            title: 'Finished?',
            text: "Do you want to return to the machines screen?",
            icon: 'info',
            showCancelButton: true,
        }).then((result) => {
            if(result.isConfirmed) {
                let url = window.location.href;
                url = window.location.origin + '/machines'
                window.location.replace(url);
            }
        })
    })
}

function listenForExpand() {
    $(".expand-machine").on("click", function() {
        let data = $.escapeSelector($(this).data('machine-id'));
        let hidden_row = $("#actions-" + data);
        if(hidden_row.is(":visible")){
            $(".actions-row").hide();
        } else {
            $(".actions-row").hide();
            hidden_row.fadeIn();
        }
    })
}

function listenForSwitch(machines_switch) {
    let machines_form = $("#machines-form");
    let type_form = $("#type-form");
    machines_switch.change(function() {
        if(this.checked) {
            machines_form.hide();
            type_form.show();
        }else{
            machines_form.show();
            type_form.hide();
        }
    })
}

function listenForMapClick() {
    $("#preview_map_machines").on("click", function() {
        let overlay = $("#machines_preview_overlay");
        overlay.show();
        overlay.fadeOut(5000);
    })
}

function listenForEditClick() {
    $(".edit-machine").on("click", function() {
        let unescaped_machine_id = $(this).data('machine-id');
        let machine_id = $.escapeSelector($(this).data('machine-id'));
        let worker_select = $(".worker_select_div_" + machine_id);
        let edit_machine_label = $(".edit-machine-label." + machine_id);
        let edit_machine_stat = $(".edit-machine-stat." + machine_id);
        let description_box = $(".machine-description-column." + machine_id);

        if($(this).data('is-save')){
            Swal.fire({
                title: 'Save Machine',
                text: "Are you sure to save these machine details?",
                icon: 'info',
                showCancelButton: true,
            }).then((value) => {
                if (value) {
                    let description = $("#description.machine-input." + machine_id).val();
                    let speed_limit = $("#speed_limit.machine-input." + machine_id).val();

                    $.ajax({
                        type: 'POST',
                        url: `${Config.apiUrl}internal_machines/save_machine_details?apikey=${Config.apiKey}`,
                        data: {
                            machine_id: unescaped_machine_id,
                            description: description,
                            speed_limit: speed_limit
                        },
                        success: data => {
                            Swal.fire("Done!", "Machine Saved!", "success")

                            $(".data-box__stat.description." + machine_id).text(description);
                            $(".data-box__stat." + machine_id).show();
                            $(".machine-input." + machine_id).hide();
                            $(".machine-description-td." + machine_id).text(description);
                            $(".data-box__stat.speed_limit." + machine_id).text(speed_limit + " mph");
                            edit_machine_stat.show();
                            edit_machine_label.html('Edit Machine');
                            edit_machine_stat.html('<i class="fas fa-edit"></i> Edit');
                            worker_select.hide();
                            $(this).data('is-save', false);
                        },
                        error: data => {
                            Swal.fire("Error trying to save machine");
                        }
                    })
                }
            })
        }else{
            $(".data-box__stat." + machine_id).hide();
            $(".machine-input." + machine_id).show();
            edit_machine_stat.show();
            edit_machine_label.html('Save Machine');
            edit_machine_stat.html('<i class="fas fa-check"></i> Save');
            worker_select.show();
            $(this).data('is-save', true);
        }
    })
}