import MapConfig from '../config/map_config';
import Config from "../config/constants";
import {
    hazardTagAjaxCall, imageDataAjaxCall,
    locationDataAjaxCall,
    machineTagLocationDataAjaxCall,
    fitMapToBounds, sectorAjaxCall, tunnelAjaxCall, tunnelTagChainAjaxCall, geofenceAjaxCall, connectDataAjaxCall, perimeterAjaxCall, machineZoneAjaxCall
} from './map_ajax';
import mapboxgl from 'mapbox-gl';
import { siteId, siteCoordinates, getInitialSite } from './site_management';
import * as turf from '@turf/turf'
import {addPolygonsMouseOver, geofenceIds} from "./map";

document.addEventListener("turbolinks:load", () => {
    if($('#preview_map_machines').length) {
        var style = MapConfig.greyStyle;
        const siteId = document.getElementById("site_id").value;
        const siteCoordinates = document.getElementById("site_coordinates").value;
        $.when(getInitialSite()).done(() => {
            initiatePreviewMap('preview_map_machines', false, siteId, siteCoordinates, false, null, false, style, true, false, true);
        })
    }
    if($('#preview_map_personnel').length) {
        var style = MapConfig.greyStyle;
        const siteId = document.getElementById("site_id").value;
        const siteCoordinates = document.getElementById("site_coordinates").value;
        $.when(getInitialSite()).done(() => {
            initiatePreviewMap('preview_map_personnel', false, siteId, siteCoordinates, false, null, false, style, false, true, false);
        })
    }
    if ($('#preview_map').length) {
        const siteId = document.getElementById("site_id").value;
        const siteCoordinates = document.getElementById("site_coordinates").value;
        let zoneType = null;
        const zoneTypeEl = document.getElementById("zone_type");
        const tunnel = document.getElementById("is_tunnel");
        let isTunnel = false;
        if (tunnel) {
         isTunnel = true;
        }
        if (zoneTypeEl){
            zoneType = zoneTypeEl.value;
        }
        $.when(getInitialSite()).done(() => {
            initiatePreviewMap('preview_map', false, siteId, siteCoordinates, false, zoneType, isTunnel, null, false, true, true);
        })
    }
    if ($('#machine_zone_preview_map').length) {
        const siteId = document.getElementById("site_id").value;
        const siteCoordinates = document.getElementById("site_coordinates").value;
        let zoneType = null;
        const zoneTypeEl = document.getElementById("zone_type");
        if (zoneTypeEl){
            zoneType = zoneTypeEl.value;
        }
        $.when(getInitialSite()).done(() => {
            initiatePreviewMap('machine_zone_preview_map', false, siteId, siteCoordinates, false, zoneType, false, null, false, false, true);
        })
    }
    if ($('#preview_map_site_dash').length) {
        const siteId = document.getElementById("site_id").value;
        const siteCoordinates = document.getElementById("site_coordinates").value;
        $.when(getInitialSite()).done(() => {
            const tunnel = $("#is_tunnel").val();
            let isTunnel = false;
            if (tunnel) {
                isTunnel = true;
            }
            initiatePreviewMap('preview_map_site_dash', false, siteId, siteCoordinates, true, null, isTunnel, null, false, true, true);
        })
    }
    if ($('#preview_map_site_dash').length && $('#access_group_site_id').length) {
        let siteId = document.getElementById("site_id").value;
        let siteCoordinates = document.getElementById("site_coordinates").value;
        $.when(getInitialSite()).done(() => {
            initiatePreviewMap('preview_map_site_dash', false, siteId, siteCoordinates, false, null, false, null, false, true, true);
        })
        $("#access_group_site_id").change(function() {
            siteId = document.getElementById('access_group_site_id').value;
            $.ajax({
                type: 'GET',
                url: `${Config.apiUrl}map_sites/get_site_coordinates?apikey=${Config.apiKey}&site_id=${siteId}`,
                dataType: 'json',
                success: data => {
                    siteCoordinates = data.coordinates;
                    $.when(getInitialSite()).done(() => {
                        initiatePreviewMap('preview_map_site_dash', false, siteId, siteCoordinates, true, null, false, null, false, true, true);
                    })
                }
            })
        });
    }
})

let mapObject;

window.setInterval(() => {
    if ($('#preview_map_site_dash').length) {
        let siteId = document.getElementById("site_id").value;
        let userIds = document.getElementById('users').value;
        let isTunnel = document.getElementById('is_tunnel').value;
        locationDataAjaxCall(mapObject, siteId, userIds, isTunnel);
        machineTagLocationDataAjaxCall(mapObject, siteId, userIds, true);
    }
}, MapConfig.locationFrequency);

const initiatePreviewMap = async (container, shouldShowMenu, siteId, siteCoordinates, showLiveLocation, zoneType, isTunnel, style, showMachineLocation, showPersonnelZones, showMachineZones) => {
    const geofenceIdsContainer = document.getElementById("geofence_ids")
    mapboxgl.accessToken = Config.mapboxAccessToken;
    let style_to_use = isTunnel ? MapConfig.darkStyle : MapConfig.regularStyle;
    if(style) {
        style_to_use = style
    }
    const map = new mapboxgl.Map({
        container: container,
        style: style_to_use,
        center: siteCoordinates.split(" "),
        zoom: MapConfig.previewStartingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax,
        preserveDrawingBuffer: true
    });
    fitMapToBounds(map, siteId, siteCoordinates);
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());

    addPolygonsMouseOver(map, 'polygons');
    addPolygonsMouseOver(map, 'machine_zones');
    map.on('load', async () => {
        let geofenceIds;
        if (geofenceIdsContainer != null) {
            geofenceIds = geofenceIdsContainer.value;
        }
        let machineZoneIds = $("#machine_zone_ids").val();
        if (isTunnel) {
            tunnelAjaxCall(map)
            let ttcIds = $('#tunnel_tag_control_ids').val();
            // Don't ask for TUTC data if we don't have/want it
            if (ttcIds) {
                ttcIds = ttcIds.split(" ");
                ttcIds.forEach((item, index) => {
                    tunnelTagChainAjaxCall(map, item, null, false, true, false)
                });
            }
            if(showLiveLocation) {
                let userIds = document.getElementById('users').value;
                locationDataAjaxCall(map, siteId, userIds, true);
            }
            if(showMachineLocation) {
                machineTagLocationDataAjaxCall(map, siteId, true, true);
            }

            mapObject = map;
        } else {
            connectDataAjaxCall(map);
            await imageDataAjaxCall(map, geofenceIds, null, null, true, true, false, shouldShowMenu, zoneType, siteId, null, false, true, false);
            perimeterAjaxCall(map, null, siteId, true, false);

            if(showPersonnelZones) {
                await geofenceAjaxCall(map, geofenceIds, null, null, true, true, false, shouldShowMenu, null, false, null, false, false, null, null, null);
            }
            if(showMachineZones) {
                let machine_ids = $("#machine_ids").val()
                let ids = machine_ids ? machine_ids.split(",") : null;
                await machineZoneAjaxCall(map, machineZoneIds, true, false, true, null, null, ids);
            }

            if(showLiveLocation) {
                let userIds;
                let userIdsContainer = document.getElementById('users');
                if (userIdsContainer) {
                    userIds = userIdsContainer.value;
                }
                locationDataAjaxCall(map, siteId, userIds, false);
            }
            if(showMachineLocation) {
                machineTagLocationDataAjaxCall(map, siteId, isTunnel, true);
            }
            hazardTagAjaxCall(map, siteId);
            mapObject = map;
            if (document.getElementById('sector_id')) {
                let sector_id = document.getElementById('sector_id').value;
                sectorAjaxCall(map, sector_id, true);
            }
        }
        toggleMapInfo();
    });
}

// This is duplicated from map_filter_toggle but for whatever reason it doesn't work on the site dashboard if its just in that file
const toggleMapInfo = () => {
    const $mapInfoToggleButton = $(".map-info-toggle");
    $mapInfoToggleButton.click((e) => {
        let map_info_container = document.querySelector('.map-info-container');
        map_info_container.classList.remove('animate__slideInRight');
        map_info_container.classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
        map_info_container.setAttribute('style', 'visibility:hidden');
    });
}