$(document).on('turbolinks:load', () => {
    let is_perimeter = $('#geofence_is_perimeter');
    if (is_perimeter.length){
        listenToPerimeterSelect(is_perimeter);
    }

    let geofence_edit_points_div = $('.geofence-edit-points');
    if (geofence_edit_points_div){
        toggleLatLongs(geofence_edit_points_div);
    }
});

function listenToPerimeterSelect(is_perimeter){
    let access_level_holder = $("#geofence_access_level_holder");
    is_perimeter.change(function() {
        let text = $(this).find(':selected').text();
        if(text == "Perimeter") {
            access_level_holder.hide();
        }else{
            access_level_holder.show();
        }
    });
}

function toggleLatLongs(geofenceEditPointsDiv){
    let toggleButton = $('#manual_entry');
    toggleButton.change(function(){
        if(this.checked){
            geofenceEditPointsDiv.show();
        }else{
            geofenceEditPointsDiv.hide();
        }
    })
}