// Hide the loading screen gif on page load
$(window).on('load', function () {
    if(window.location.href.indexOf('map') < 0) {
        $(".progress-bar").hide();
    }else {
        $(".progress-bar").show();
        $("body").css("cursor", "progress");
    }
    let constants = document.getElementById('event_id_constants').value;
    global.EVENT_IDS = JSON.parse(constants);
});