import MapConfig from '../config/map_config';
import Config from "../config/constants";
import { perimeterAjaxCall } from './map_ajax';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf'

document.addEventListener("turbolinks:load", () => {
    if ($("#site_perimeter_selection").length > 0){
        showOrHideMap();
    }
    listenSitePerimeterChange();
})



const initiatePerimeterMap = (coordinates, perimeterId, siteId) => {
    mapboxgl.accessToken = Config.mapboxAccessToken;
    const map = new mapboxgl.Map({
        container: 'perimeter_map',
        style: 'mapbox://styles/sroberts95/ckjssbjs10v9519ozm81mugy9',
        center: coordinates.split(", "),
        zoom: MapConfig.previewStartingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', function() {
        perimeterAjaxCall(map, perimeterId, siteId, true, true);
    })
}

function listenSitePerimeterChange() {
    $("#site_perimeter_selection").change(function() {
        showOrHideMap();
    });
}

function showOrHideMap() {
    var selectedPerimeter = $("#site_perimeter_selection").find(':selected').text();
    var selectedSiteId = document.getElementById('site-perimeter-site-id').value;
    var companyId = document.getElementById('site_company_id').value;
    var perimeterMap = $("#perimeter_map");

    if (selectedPerimeter != "None") {
        // CSS is not sticking from Map.scss for this element, no idea why, doing it manually for now works
        perimeterMap.css('height', '500px');
        perimeterMap.css('width', '500px');
        perimeterMap.css('overflow', 'visible');
        perimeterMap.show();
        $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}geofences/get_perimeter_coordinates?apikey=${Config.apiKey}&fence_name=${selectedPerimeter}&company_id=${companyId}`,
            dataType: 'json',
            success: data => {
                let coordinates = data.coordinates;
                let perimeterId = data.perimeter_id;
                initiatePerimeterMap(coordinates, perimeterId, selectedSiteId);
            }
        })
    } else {
        perimeterMap.hide();
    }
}