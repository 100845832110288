import selectize from "selectize";
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");
require("@rails/ujs").start();

// After installing a 3rd party JS library through Yarn we need it to be included in webpacker
// Instructions of how to do this are dependent on each JS library and should be in their getting started docs
// Webpacker/ES6 also treats each JS file as its own scope / seperate module
// apparently this is good for de-cluttering the global scope
// this means you need to import a 3rd party library into each JS file you want to use it in
// or you can import it here and make it available in a global variable so it can be used everywhere (helpful for a library you will use everywhere like jQuery)
import jQuery from "jquery"
global.$ = jQuery;
global.jQuery = jQuery;

require('jquery-ui-dist/jquery-ui');
require("custom/clear_search")
require("custom/password_strength")
require("custom/burger_menu")
require("custom/classie")
require("custom/user_form")
require("custom/disable_link")
require("custom/sync_device_button")
require("custom/tooltips")
require("custom/delete_notification")
require("custom/map/map")
require("custom/map/geofence_map")
require("custom/map/preview_map")
require("custom/map/site_perimeter_preview")
require("custom/map/site_management")
require("custom/map/datepicker")
require("custom/map/map_filter_toggle")
require("custom/map/site_map")
require("custom/slideout-menu/slideout")
require("custom/slideout-menu/bulma_collapse")
require("custom/config/moment_config")
require("custom/populate_user_dropdown")
require("custom/modal")
require("custom/connect_modal")
require("custom/company_api_key_modal")
require("custom/send_sensitive_data_auth_code")
require("custom/biosite_api_key_form")
require("custom/event_log_filters")
require("custom/alerts_form")
require("custom/file_management")
require("custom/populate_groups_dropdown")
require("custom/populate_groups_workers")
require("custom/site_dashboard_live_stats")
require("custom/populate_connect_site_dropdown")
require("custom/populate_hub_site_dropdown")
require("custom/populate_machine_tag_site_dropdown")
require("custom/config_value_control")
require("custom/access_group_form")
require("custom/loading_animation")
require("custom/geofence_form")
require("@mapbox/mapbox-sdk")
require("aws-sdk")
require("chartkick/chart.js")
require("custom/canvas")
// require("custom/alert_checker")
require("jspdf")
require("web-push")
require("mqtt")
require("custom/selectize_menus")
require("custom/sites_dashboard_charts")
require("custom/add_devices_form")
require("custom/broken_lost_product")
require("custom/qr_code_scanner")
require("custom/orders_form")
require("custom/user_feedback")
require("custom/sites_dashboard_updates_handler")
require("custom/user_error_report")
require("custom/disconnect_listener")
require("custom/map/tunnel_tag_map")
require("custom/tunnel_tag_control_form")
require("custom/tunnel_tag_form")
require("custom/disable_timelines")
require("custom/zone_manual_entry_form")
require("custom/page_load")
require("custom/mock_ihub_add_competencies")
require("custom/personnel")
require("custom/machines")
require("custom/process_zone_interaction_modal_listener")
